import { GetStaticProps } from 'next'
import { getTokenDetails } from '../lib/tokens'

export const getStaticProps: GetStaticProps = async (context) => {
  const symbol = context.params?.symbol
  const details = getTokenDetails(symbol as string)

  if (!details) {
    return {
      notFound: true,
    }
  } else {
    return {
      redirect: {
        destination: `/daily-analysis/${details.slug}`,
        permanent: true,
      },
    }
  }
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  }
}

function SymbolRedirect() {
  return null
}

export default SymbolRedirect
